<template>
  <div>
    <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Rates</h3>
        
        
    </md-toolbar>
    <md-progress-bar md-mode="indeterminate" v-if="isBusy"></md-progress-bar>
      <div class="main-inner-wrapper">
        <!--draggable v-model="vehicleTypes" tag="div" @change="listUpdated" class="row pt-4"-->
        <CRow class="pt-4">  
           <CCol v-for="vehicleType in vehicleTypes" v-bind:key="vehicleType.id" sm="12" md="12" >
                <md-card md-with-hover class="mb-3" >
                  <div class="md-layout md-alignment-center-left p-2" @click="goToRate(vehicleType)">
                    <div class="md-layout-item flex-none">
                      <div class="special-avatar">
                        <img :src="vehicleType.pictures[0]" alt="People">
                      </div>
                    </div>
                    <div class="md-layout-item flex-1 pl-2 pr-1">
                      <div class="mb-1 md-body-2">{{vehicleType.type}}</div>
                      <div class="md-subhead"><md-icon>luggage</md-icon>{{vehicleType.luggage}} <md-icon class="ml-2">people_alt</md-icon> {{vehicleType.passengers}}</div>
                    </div>
                    <div class="md-layout-item flex-none">
                      <md-button @click="goToRate(vehicleType)">
                          Manage rate
                          <md-icon class="ml-3">settings</md-icon>
                      </md-button>
                    </div>
                  </div>
                </md-card>
            </CCol>
        </CRow>
        <!--/draggable-->
         

        <!--CRow class="pt-4">
            <CCol v-for="vehicleType in vehicleTypes" v-bind:key="vehicleType.id" sm="12" md="12" >
                <md-card md-with-hover class="mb-3" >
                    <div @click="goToRate(vehicleType)">
                        <md-card-media v-if="vehicleType.pictures">
                          <img :src="vehicleType.pictures[0]" alt="People">
                        </md-card-media>
                        <md-ripple >
                            <md-card-header>
                            <div class="md-title mb-2">{{vehicleType.type}}</div>
                            <div class="md-subhead"><md-icon>luggage</md-icon>{{vehicleType.luggage}} <md-icon class="ml-2">people_alt</md-icon> {{vehicleType.passengers}}</div>
                            </md-card-header>

                            <md-card-content>
                            </md-card-content>

                            <md-card-actions>
                                
                                
                            </md-card-actions>
                        </md-ripple>
                    </div>
                  </md-card>
            </CCol>
        </CRow-->
      </div>
  </div>
</template>

<script>
import firebase from '../../Firebase'
import CTableWrapper from '../base/Table'
import draggable from 'vuedraggable'
export default {
  name: 'Rates',
  components: { CTableWrapper, draggable },
  data() {
    return {
      rates: [],
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('rates'),
      //items: usersData,
      vehicleTypes: [],
      activePage: 1,
      isBusy: true
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  created () {
    firebase.firestore().collection('users').doc(this.user.data.uid).collection('rates').onSnapshot((querySnapshot) => {
      this.rates = [];
      querySnapshot.forEach((doc) => {

        let dataToSave = doc.data();
        dataToSave.id = doc.id ;
        this.rates.push(dataToSave);

      });
    });

    firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').orderBy('order').onSnapshot((querySnapshot) => {
      this.vehicleTypes = [];
      querySnapshot.forEach((doc) => {

        let dataToSave = doc.data();
        dataToSave.id = doc.id ;
        this.vehicleTypes.push(dataToSave);
        

      });
      this.isBusy = false;
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    goToRate(vehicleType) {
        console.log('Go to rate');
        console.log(vehicleType);
        this.$router.push(
          { 
            path: `/app/rates/${vehicleType.type}/${vehicleType.id}`,
          }
        )
    },
    rowClicked (item, index) {
      console.log(item);
      this.$router.push(
          { path: `/app/rates/${id}`,
            
          }
        )
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    newVehicleType(){
      this.$router.push({
        path: `/app/rates/new`})
    },
    listUpdated(ev){
      console.log(ev);
      this.updateOrder();
    },
    updateOrder() {
      let th = this;
      for (let index = 0; index < this.rates.length; index++) {
          this.rates[index].order = index + 1;
          firebase.firestore().collection('users').doc(this.user.data.uid).collection('rates').doc(this.rates[index].id).update({
            order: index + 1
          })
          .then(function(){
              console.log('Updated');
          }).catch(function(error) {
             console.log("Error getting document:", error);
          });
      }
      
    },
  }
}
</script>